import { css } from '@emotion/core'

export const navBar = css`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  min-height: 100px;
  max-height: 100px;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.30);
  font-family: 'Montserrat', sans-serif;
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1286px;
  height: 100%;
`
export const logo = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 0px) and (max-width: 1317px) {
    margin-left: 10px;
  }
`
export const navBarMenu = css`
  display: flex;
  height: 100%;
  min-height: 80px;
  color: #5763ad;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 2px;
  align-items: center;
  cursor: pointer;
  & > a {
    color: #5763ad;
  }
  div:not(:last-child), a:not(:last-child) {
    margin-right: 24px;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: none
  }
  @media (min-width: 0px) and (max-width: 960px) {
    background: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
  }
`
export const btn = css`
  border-radius: 30px;
  color: #fff;
  width: 176px;
  height: 48px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.23px;
  font-weight: bold;
  line-height: 19px;
  flex-direction: inherit;
  justify-content: center;
  box-sizing: border-box;
  img {
    margin-right: 8px;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    border-radius: 0px;
    margin-left: 0px;
    width: 100%;
  }
  
`
export const SingUpBtn = css`
  ${btn}
  color: #fff !important;
  font-weight: bold;
  align-items: center;
  padding-bottom: 4px;
  background: #026FC2;
  border-radius: 4px;
  @media (min-width: 0px) and (max-width: 960px) {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    font-size: 30px;
  }
`
export const LoginBtn = css`
  ${btn}
  background-color: #fff;
  color: #026FC2 !important;
  padding: 16px 18px;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #026FC2;
  font-weight: bold;
  @media (min-width: 0px) and (max-width: 960px) {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    font-size: 30px;
  }
`
export const GoToDashboard = css`
  ${btn}
  background-color: #4285F4;
  border-radius: 0 0 5px 0;
  :hover {
    background-color: #6F9CEB;
    color: #fff;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
export const navMenu = css`
  margin-left: auto;
  align-items: center;
`
export const mobileMenu = css`
  & > div:nth-child(1) {
    display: flex;
    margin: 25px;
    cursor: pointer;
  }
  & > div:nth-child(2) {
    background: #fff;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    color: #171515;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 19px;
    border-top: 1px solid #efefef;
    cursor: pointer;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.30);
    div {
      padding: 12px 0 12px 0;
    }
  }
`
export const togle = css`
  display: flex;
  align-items: center;
  color: #121212 !important;
  font-weight: bold;
  div:hover, a:hover {
    color: #026FC2;
    transition: color 0.25s ease-out;
  }
  a {
    color: rgba(18, 18, 18, 0.8) !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    :hover {
      color: #4783C6 !important;
      font-weight: bold;
    }
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: flex;
    align-items: flex-start;
    margin-left: 32px;
    flex-direction: column;
    a {
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }
`
export const linkTo = css`
  color: #979797 !important;
  :hover {
    color: #026FC2 !important;
    transition: color 0.25s ease-out;
  }
`
export const socialIcons = css`
  margin-left: 0;
  @media (min-width: 0px) and (max-width: 960px) {
    a {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`
export const editionTitle = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(18, 18, 18, 0.8);
  font-family: 'Montserrat', sans-serif;
`

export const menuPocket = css`
  @media (min-width: 1001px) {
    display: none !important;
  }
`
export const mobile = css` 
  
`
  