import { css } from '@emotion/core'
// import BgInterna from '../../images/bg-internas.png'
import Bg1 from '../../images/banner/bg.png'
import Bg2 from '../../images/banner/bg-2a-guerra.jpg'
import Bg3 from '../../images/banner/bg-1984.png'
import Bg4 from '../../images/banner/bg-agro.png'
import Bg5 from '../../images/banner/bg-aula.png'
import Bg6 from '../../images/banner/bg6.png'
import Bg7 from '../../images/app/agro-desktop.png'
import Bg8 from '../../images/banner/bg-1984-2.png'
import Bg9 from '../../images/app/1984-desktop.png'
import Bg10 from '../../images/app/pocket-desktop.png'
import Bg11 from '../../images/banner/hackeando-bg.png'
// import Bg3 from '../../images/projetos/wefood.png'
// import Bg4 from '../../images/projetos/meliponas.png'
// import Bg5 from '../../images/projetos/nave.png'
// import Bg6 from '../../images/projetos/sita.png'
// import Cartaz from '../../images/CARTAZ_inscricoes.png'
// import Cartaz2 from '../../images/CARTAZ_participante.png'

export const header = css`
  position: relative;
  z-index: 1;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #FFFFFF;
    @media (min-width: 260px) and (max-width: 736px) {
      font-size: 42px;
    }
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    img {
      margin-right: 16px;
    }
  }
`
export const title = css`
  font-family: 'Montserrat', sans-serif;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  border-radius: 36px;
  outline: none;
  margin-left: 0px;
  color: #2E9E93;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const title2 = css`
  font-family: 'Montserrat', sans-serif;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  border-radius: 36px;
  outline: none;
  margin-left: 0px;
  color: #8C7A69;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const title3 = css`
  font-family: 'Montserrat', sans-serif;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  border-radius: 36px;
  outline: none;
  margin-left: 0px;
  color: #00078C;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const title4 = css`
  font-family: 'Montserrat', sans-serif;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  border-radius: 36px;
  outline: none;
  margin-left: 0px;
  color: #75A65F;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`

export const btn = css`
  font-family: 'Montserrat', sans-serif;
  width: 328px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #2E9E93;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const btn2 = css`
  font-family: 'Montserrat', sans-serif;
  width: 328px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #8C7A69;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const btn3 = css`
  font-family: 'Montserrat', sans-serif;
  width: 328px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #00078C;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const btn4 = css`
  font-family: 'Montserrat', sans-serif;
  width: 328px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #75A65F;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const btn5 = css`
  font-family: 'Montserrat', sans-serif;
  width: 328px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #00078C;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  border: 2px solid #fff;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`
export const btn6 = css`
  font-family: 'Montserrat', sans-serif;
  width: 328px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #9d1216;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
  }
`

export const btn7 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #022433 !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`

export const arrow = css`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex-direction: column;
  cursor: pointer;
  img {
    margin-top: 28px;
  }
`

export const content = css`
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 96%;
    color: #fff;
    margin-bottom: 8px;
  }
  p { 
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
  }
  @media (min-width: 260px) and (max-width: 940px) {
    flex-direction: column;
  }
`
export const StudentsBox = css`
  background: #7379FF;
  width: 50%;
  height: 290px;
  display: flex;
  align-items: center;
  @media (min-width: 260px) and (max-width: 940px) {
    width: 100%;
  }
`
export const GraduateBox = css`
  background: #545CFF;
  width: 50%;
  height: 290px;
  display: flex;
  align-items: center;
  @media (min-width: 260px) and (max-width: 940px) {
    width: 100%;
  }
`
export const TerceiroBox = css`
  background-size: cover;
  background-position: center;
  width: 50%;
  height: 600px;
  display: flex;
  align-items: center;
  @media (min-width: 260px) and (max-width: 940px) {
    width: 100%;
  }
`
 
export const boxContent= css`
  width: 450px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (min-width: 260px) and (max-width: 940px) {
    margin: 16px;
  }
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 1130px;
  h1 {
    font-size: 48px;
    line-height: 100%;
    text-align: left;
    color: #fff;
    font-weight: normal;
    margin-top: 24px;
  }
  h2 {
    font-size: 48px;
    line-height: 100%;
    text-align: left;
    color: #fff;
    padding-bottom: 28px;
    font-weight: bold;
  }
  h4 {
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: #026FC2;
    margin-bottom: 24px;
    font-weight: bold;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    h1 {
      margin-right: 24px;
      margin-left: 24px;
      font-size: 40px;
    }
    h2 {
      margin-right: 24px;
      margin-left: 24px;
    }
  }
`
export const columns = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`
export const column = css`
  flex: 0;
  /*box-shadow: 0 11px 10px 0 rgba(0,0,0,0.09);
  border-radius: 5px;*/
  transition: all .3s ease-out;
  cursor: default;
  margin: 2px;
  padding: 10px;
  img {
    width: 500px;
  }
  &:first-child { 
    margin-left: 0;
    margin-right: 0; 
  }
  &:last-child { 
    margin-left: 0;
    margin-right: 0; 
  }
  /*:hover {
    -webkit-box-shadow: 0 0 30px 0px rgba(0,0,0,0.18);
  }*/
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    &:first-child {
      margin-right: 0; 
    }
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`

export const StepNumber = css`
  width: 150px;
  margin-bottom: 32px;
  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    color: #363732;
    margin-bottom: 8px;
    height: 68px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
  }
`

export const ProjectBox = css`
  width: 400px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.5s ease;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #026FC2;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 14px;
    line-height: 17px;
    color: #3FA9F5;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    line-height: 120%;
    color: #515151;
  }
  /* :hover {
    box-shadow: 0 5px 25px rgba(0,0,0,.1);
    transform: translateY(-1px);
  } */
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    text-align: left !important;
  }
`

export const visionBox = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  p {
    font-size: 24px;
    line-height: 120%;
    color: #C4EFFA;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
`
export const VideoBox = css`
  position: relative;
  width: 505px;
  height: 336px;
  background: #202020;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.25s linear 0s;
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #fff;
    margin-top: 16px;
    text-align: center;
  }
  img {
    width: 60px;
    :hover {
      opacity: 0.8;
      transition: opacity 0.4s;
    }
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
  }
  /* @media (min-width: 260px) and (max-width: 980px) {
    margin-bottom: 80px;
  } */
`
export const orderMbile = css`
  @media (min-width: 260px) and (max-width: 736px) {
    order: 2;
  }
`
export const top4 = css`
  background-color: #F3FAFF;
  padding-top: 60px;
  padding-bottom: 60px;
  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #3FA9F5;
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 24px;
  }
`
export const mencoesHonrosas = css`
  background-color: #EEF5FA;
  padding-top: 60px;
  padding-bottom: 60px;
  h1 {
    font-size: 48px;
    line-height: 100%;
    text-align: left;
    color: #1072BA !important;
    padding-bottom: 28px;
    margin-top: 0 !important;
    span {
      font-weight: bold;
    }
  }
`
export const contentView = css`
  position: relative;
  width: 328px;
  background: #FFFFFF;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: default;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  transition: all 0.25s linear 0s;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #026FC2;
    margin-bottom: 16px;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    span {
      font-weight: normal;
      margin-left: 34px;
    }
    img {
      width: 24px;
      margin-right: 8px;
    }
  }
  h3 {
    font-size: 14px;
    line-height: 17px;
    color: #3FA9F5;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  p {
    font-size: 16px;
    line-height: 200%;
    color: #444444;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    height: 210px;
  }
  img {
    cursor: pointer;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    height: auto;
  }
`
export const bgHeader = css`
  background: #1072BA;
  height: 563px;
`
export const bg1 = css`
  background: url(${Bg1}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`
export const bg2 = css`
  background: url(${Bg2}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`
export const bg3 = css`
  background: url(${Bg3}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`
export const bg4 = css`
  background: url(${Bg4}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`

export const bg5 = css`
  background: url(${Bg5}) no-repeat;
  background-size: contain;
  background-position: center;
  height: 420px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 38px;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
  @media (min-width: 260px) and (max-width: 736px) {
    background: url(${Bg6}) no-repeat;
    background-size: contain;
    margin-top: 100px;
  }
`

export const bg7 = css`
  background: url(${Bg7}) no-repeat;
  background-size: contain;
  background-position: center;
  height: 480px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 80px;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
  /* @media (min-width: 260px) and (max-width: 736px) {
    background: url(${Bg6}) no-repeat;
    background-size: contain;
    margin-top: 100px;
  } */
`

export const bg8 = css`
  background: url(${Bg8}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px;
  align-items: center;
  justify-content: center;
  display: flex;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`
export const bg9 = css`
  background: url(${Bg9}) no-repeat;
  background-size: contain;
  background-position: center;
  height: 480px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 80px;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
  /* @media (min-width: 260px) and (max-width: 736px) {
    background: url(${Bg6}) no-repeat;
    background-size: contain;
    margin-top: 100px;
  } */
`
export const bg10 = css`
  background: url(${Bg10}) no-repeat;
  background-size: contain;
  background-position: center;
  height: 480px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 80px;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
  /* @media (min-width: 260px) and (max-width: 736px) {
    background: url(${Bg6}) no-repeat;
    background-size: contain;
    margin-top: 100px;
  } */
`

export const bg11 = css`
background: url(${Bg11}) no-repeat;
background-size: cover;
background-position: center;
height: 908px;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
img {
  transition: 0.5s all ease;
  :hover {
    opacity: 0.9;
    transition: opacity 0.9s all ease;
  }
}
`
// // export const bg3 = css`
// //   background: url(${Bg3}) no-repeat;
// //   background-size: cover;
// //   background-position: center;
// //   height: 208px;
// //   align-items: center;
// //   justify-content: center;
// //   display: flex;
// //   img {
// //     transition: 0.5s all ease;
// //     :hover {
// //       opacity: 0.9;
// //       transition: opacity 0.9s all ease;
// //     }
// //   }
// // `
// // export const bg4 = css`
// //   background: url(${Bg4}) no-repeat;
// //   background-size: cover;
// //   background-position: center;
// //   height: 208px;
// //   align-items: center;
// //   justify-content: center;
// //   display: flex;
// //   img {
// //     transition: 0.5s all ease;
// //     :hover {
// //       opacity: 0.9;
// //       transition: opacity 0.9s all ease;
// //     }
// //   }
// // `
// // export const bg5 = css`
// //   background: url(${Bg5}) no-repeat;
// //   background-size: cover;
// //   background-position: center;
// //   height: 208px;
// //   align-items: center;
// //   justify-content: center;
// //   display: flex;
// //   img {
// //     transition: 0.5s all ease;
// //     :hover {
// //       opacity: 0.9;
// //       transition: opacity 0.9s all ease;
// //     }
// //   }
// // `
// // export const bg6 = css`
// //   background: url(${Bg6}) no-repeat;
// //   background-size: cover;
// //   background-position: center;
// //   height: 208px;
// //   align-items: center;
// //   justify-content: center;
// //   display: flex;
// //   img {
// //     transition: 0.5s all ease;
// //     :hover {
// //       opacity: 0.9;
// //       transition: opacity 0.9s all ease;
// //     }
// //   }
// // `
// // export const kitItem = css`
// //   background: url(${Cartaz}) no-repeat;
// //   background-size: cover;
// //   background-position: center;
// //   height: 420px;
// //   align-items: center;
// //   justify-content: center;
// //   display: flex;
// //   img {
// //     transition: 0.5s all ease;
// //     :hover {
// //       opacity: 0.9;
// //       transition: opacity 0.9s all ease;
// //     }
// //   }
// // `
// // export const kitItem2 = css`
//   background: url(${Cartaz2}) no-repeat;
//   background-size: cover;
//   background-position: center;
//   height: 420px;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   img {
//     transition: 0.5s all ease;
//     :hover {
//       opacity: 0.9;
//       transition: opacity 0.9s all ease;
//     }
//   }
// `
export const contentSapientia = css`
  max-width: 800px;
  margin: 0 auto;
  h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #1072BA;
    max-width: 670px;
    text-transform: uppercase;
    padding-bottom: 12px !important;
  }
  h3 {
    font-size: 22px;
    line-height: 29px;
    color: #026FC2;
    margin-bottom: 24px;
  }
  h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    color: #026FC2;
    margin-bottom: 24px;
    margin-top: 36px;
    text-align: left;
  }
  img {
    width: 100%;
  }
  p {
    font-size: 18px;
    line-height: 170%;
    color: #000000;
    span {
      font-weight: bold;
      font-size: 14px !important;
      a {
        font-weight: normal;
        color: #000000;
        :hover {
          color: #026FC2;
        }
      }
    }
    :not(:last-child) {
      margin-bottom: 12px;
    } 
  }
  p:not(:last-child) {
    margin-bottom: 12px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    h2 {
      margin: 0 16px;
      margin-bottom: 24px;
    }
    h3 {
      margin: 0 16px;
      margin-bottom: 24px;
    }
    h4 {
      margin: 0 16px;
    }
    p {
      margin: 0 16px;
    }
  }
`
export const formulario = css`
  max-width: 580px;
  width: 100%;
  background: #21567E;
  border-radius: 4px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px;
  @media (min-width: 260px) and (max-width: 600px) {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

export const label = css`
  margin-bottom: 24px;
  max-width: 580px;
  width: 100%;
  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 10px;
  }
  input {
    width: 100%;
    max-width: 556px;
    height: 52px;
    border-radius: 4px;
    border: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: #AAAAAA;
    padding-left: 24px;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  textarea {
    width: 100%;
    max-width: 556px;
    border-radius: 4px;
    border: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: #AAAAAA;
    padding-top: 24px;
    padding-left: 20px;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    p {
      margin-left: 14px
    }
    input {
      width: 328px;
      height: 40px;
    }
    textarea {
      width: 328px;
    }
  }
`

export const btnDownload = css`
  display: flex;
  border: 2px solid #026FC2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 158px;
  height: 42px;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  justify-content: center;
  align-items: center;
  color: #026FC2;
  margin: 0 auto;
  margin-bottom: 50px;
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
// export const bgInterna = css`
//   background: #1072BA;
//   height: 150px;
//   display: flex;
//   justify-content: center;
//   margin-top: 100px;
//   background: url(${BgInterna}) no-repeat;
//   background-size: cover;
//   background-position: center;
// `

// export const bgMidia = css`
//   background: #1072BA;
//   height: 150px;
//   display: flex;
//   justify-content: center;
//   margin-top: 100px;
//   background: url(${BgInterna}) no-repeat;
//   background-size: cover;
//   background-position: center;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   text-align: center;
//   h1 {
//     text-align: center;
//     align-items: center;
//     justify-content: center;
//     font-weight: bold;
//   }
// `

export const borderLine = css`
  border: 1px solid #E1E1E1;
  margin-top: 52px;
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 16px;
  }
`

export const modalVideo = css`
  width: 940px;
  height: 564px;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 300px;
    height: 210px;
  }
`

export const desktop = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none !important;
  }
`
export const mobile = css`
  @media (min-width: 1001px) {
    display: none !important;
  }
`
export const contentHeader = css`
  display: flex;
  @media (min-width: 260px) and (max-width: 1000px) {
    flex-direction: column;
  }
`
export const contentItem = css`
  width: 50%;
  @media (min-width: 260px) and (max-width: 1000px) {
    width: 100%;
  }
`
export const contentNew = css`
  padding: 0 40px;
  @media (min-width: 260px) and (max-width: 1000px) {
    padding: 0px;
  }
`