import { css } from '@emotion/core'

export const inspired = css`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
`
export const title = css`
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: #141414;
  text-transform: uppercase;
  span {
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */

    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;

    color: #F5FF00;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
  }
`

export const titleVideo = css`
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 38px !important;
  line-height: 100% !important;
  letter-spacing: 0.05em !important;
  color: #555555 !important;
  margin-bottom: 32px !important;
  text-align: left !important;
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
  }
`

export const contentView = css`
  position: relative;
  /* padding: 36px 24px 36px 24px;
  width: 322px; */
  background: #FFFFFF;
  /* box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.2); */
  border-radius: 4px;
  cursor: default;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  transition: all 0.25s linear 0s;
  text-align: center;
  h2 {
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 72px !important;
    line-height: 100% !important;
    letter-spacing: 0.05em !important;
    color: #555555 !important;
    margin-bottom: 32px !important;
    text-align: left !important;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.05em;
      color: #555555;
    }
    @media (min-width: 260px) and (max-width: 736px) {
      font-size: 42px !important;
    }
  }
  h3 {
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 36px !important;
    line-height: 120% !important;
    letter-spacing: 0.05em !important;
    color: #555555 !important;
    margin-bottom: 32px !important;
    text-align: left !important;
    margin-top: 40px;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.05em;
      color: #555555;
    }
    @media (min-width: 260px) and (max-width: 736px) {
      font-size: 42px !important;
    }
  }
  h4 {
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 60px !important;
    line-height: 100% !important;
    letter-spacing: 0.05em !important;
    color: #555555 !important;
    margin-bottom: 32px !important;
    text-align: left !important;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0.05em;
      color: #555555;
    }
    @media (min-width: 260px) and (max-width: 736px) {
      font-size: 42px !important;
    }
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 250%;
    letter-spacing: 0.05em;
    color: #737373;
    text-align: left;
    font-family: 'Merriweather', serif;
    span {
      font-weight: bold;
      font-size: 24px;
      line-height: 250%;
      letter-spacing: 0.05em;
      color: #737373;
      font-family: 'Merriweather', serif;
    }
    b {
      color: #555555 !important;
      font-weight: bold;
    }
    a {
      font-weight: bold;
    }
    a:hover {
      color: #555555;
    }
    @media (min-width: 260px) and (max-width: 736px) {
      text-align: left;
    }
  }
  /* :hover {
    box-shadow: 0 5px 25px rgba(0,0,0,.1);
    transform: translate(0px, -4px);
  } */
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    margin: 0 18px
  }
`

export const columns = css`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    width: 100%;
  }
`

export const column = css`
  flex: 1 0 18%; 
  /* margin: 12px; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 auto;
  }
`

export const container = css`
  position: relative;
  z-index: 2;
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const btn = css`
  width: 328px;
  height: 65px;
  border-radius: 4px;
  background: #026FC2;
  /* border: 1px solid #026FC2; */
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 18px; 
  line-height: 22px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
export const btnMoreVideo = css`
  width: 328px;
  height: 65px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #555555;
  color: #555555;
  align-items: center;
  justify-content: center;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 16px; 
  line-height: 22px;
  cursor: pointer;
  outline: none;
  a {
    color: #555555;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
export const visionBox = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.5s ease;
`

export const subscription = css`
  background: #E8E8E8;
  max-width: 400px;
  width: 100%;
  padding: 42px 32px;
  iframe {
    height: 755px;
  }
  h4 {
    font-weight: bold;
    font-size: 42px;
    line-height: 105%;
    text-align: center;
    text-transform: uppercase;
    color: #00078C;
    margin-bottom: 32px;
  }
  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 32px;
  }
  form {
    display: flex;
    flex-direction: column;
    
    label {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 22px;
      color: #555555;
      font-family: 'Montserrat', sans-serif;
    }
    input {
      margin-top: 12px;
      height: 42px;
      border-radius: 3px;
      border: none;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #B9B9B9;
      padding-left: 12px;
    }
    select {
      margin-top: 12px;
      border-radius: 3px;
      height: 42px;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #B9B9B9;
      border: none;
      background: #fff;
      padding-left: 12px;
      font-family: 'Montserrat', sans-serif;
    }
    button {
      padding-top: 16px;
      padding-bottom: 16px;
      border: none;
      margin-top: 20px;
      width: 100%;
    }
  }
`

export const formMt = css`
  margin-top: -160px;
  @media (min-width: 260px) and (max-width: 736px) {
    margin-top: 0;
  }
`

export const pFont = css`
  h3 {
    font-family: 'Montserrat', sans-serif !important  ;
    text-transform: uppercase;
  }
  p {
    font-family: 'Montserrat', sans-serif !important  ;
  }
`

export const contenInfo = css`
  padding-top: 62px;
  padding-bottom: 62px;
  flex-direction: column;
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 250%;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-align: left;
    @media (min-width: 260px) and (max-width: 736px) {
      margin: 0 18px
    }
  }
`
export const titleApp = css`
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #555555;
  font-family: 'Montserrat', sans-serif !important;
  span {
    font-weight: bold;
  }
`

export const ctaApp = css`
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #555555;
  margin-bottom: 18px;
  font-family: 'Montserrat', sans-serif !important;
`

export const titlesApp = css`
  display: flex;
  flex-direction: column;
  max-width: 432px;
  width: 100%;
  text-align: left;
  margin-left: 400px;
`

export const linksApps = css`
  margin-left: 400px;
  margin-top: 24px;
  a:first-child {
    margin-right: 8px;
  }
  a:hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px transparent, 0 3px 6px transparent;
  }
`