import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  SmallMenu,
  LargeMenu
} from './styles/TemplateNavbar.styles'

const MenuIcon = ({ onClick, icon }) => (
  <div role='button' tabIndex='0' onClick={onClick}>
    {icon}
  </div>
)

class ResponsiveMenu extends Component {
  handleClick = () => {
    this.props.setMenu(!this.props.menuVisible)
  }

  render () {
    const {
      menu,
      largeMenuClassName,
      smallMenuClassName,
      changeMenuOn,
      menuOpenButton,
      menuCloseButton,
      menuVisible
    } = this.props

    return (
      <div>
        <LargeMenu css={largeMenuClassName} size={changeMenuOn}>
          {menu}
        </LargeMenu>
        <SmallMenu css={smallMenuClassName} size={changeMenuOn}>
          {!menuVisible ? (
            <MenuIcon onClick={this.handleClick} icon={menuOpenButton} />
          ) : (
            <MenuIcon onClick={this.handleClick} icon={menuCloseButton} />
          )}
          <div style={{ display: menuVisible ? 'block' : 'none' }}>{menu}</div>
        </SmallMenu>
      </div>
    )
  }
}

ResponsiveMenu.propTypes = {
  menu: PropTypes.node.isRequired,
  largeMenuClassName: PropTypes.string,
  smallMenuClassName: PropTypes.string,
  changeMenuOn: PropTypes.string.isRequired,
  menuOpenButton: PropTypes.node.isRequired,
  menuCloseButton: PropTypes.node.isRequired,
  setMenu: PropTypes.func.isRequired,
  menuVisible: PropTypes.bool.isRequired
}

ResponsiveMenu.defaultProps = {
  largeMenuClassName: '',
  smallMenuClassName: ''
}

export default ResponsiveMenu
