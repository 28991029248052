import { css } from '@emotion/core'

export const inspired = css`
  position: relative;
  /* padding-top: 4rem; */
  padding-bottom: 4rem;
  background: #fff;
`
export const title = css`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.15em;
  color: #555555;
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
    text-align: center;
  }
`

export const subTitle = css`
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.15em;
  color: #555555;
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
  }
`

export const contentView = css`
  position: relative;
  /* padding: 36px 24px 36px 24px;
  width: 322px; */
  background: #FFFFFF;
  /* box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.2); */
  border-radius: 4px;
  cursor: default;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  transition: all 0.25s linear 0s;
  /* text-align: center; */
  /* h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #026FC2;
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: 'Montserrat', sans-serif;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #777777;
    font-family: 'Montserrat', sans-serif;
    margin-top: 24px;
  } */
  img {
    margin-right: 16px;
  }
  /* :hover {
    box-shadow: 0 5px 25px rgba(0,0,0,.1);
    transform: translate(0px, -4px);
  } */
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    flex-direction: column;
    img {
      margin-left: 0px;
      margin-bottom: 16px;
    }
  }
  /* @media (min-width: 260px) and (max-width: 980px) {
    margin-bottom: 80px;
  } */
`

export const columns = css`
  display: flex;
  /* flex-wrap: wrap; */
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    width: 100%;
  }
`

export const column = css`
  /* flex: 1 0 18%;  */
  display: flex;
  /* align-items: center;
  justify-content: center; */
  width: 50%;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
  }
`

export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const btn = css`
  width: 328px;
  height: 65px;
  border-radius: 4px;
  background: #026FC2;
  /* border: 1px solid #026FC2; */
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 18px; 
  line-height: 22px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
export const visionBox = css`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.5s ease;
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.05em;
    color: #737373;
    font-family: 'Merriweather', serif;
    width: 460px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    p {
      margin: 0 18px;
      width: 100%;
      max-width: 328px;
    }
  }
`