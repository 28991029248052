import { css } from '@emotion/core'

export const globalStyles = css`
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
		margin: 0 !important;
	}
	html {
		font-family: 'Barlow Semi Condensed', sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	a {
		text-decoration: none;
	}
	/* TABS */
	.react-tabs__tab-list {
		border-bottom: 1px solid #E4EAEE;
		padding: 0;
		display: flex;
		font-size: 14px;
		color: #CCCCCC;
		height: auto;
		display: flex;
	}

	.react-tabs__tab {
		display: block;
		border-top: none;
		border-right: none;
		border-left: none;
		text-align: center;
		bottom: -1px;
		position: relative;
		list-style: none;
		padding: 25px 12px;
		cursor: pointer;
		width: 100%;
		&:not(:has('.react-tabs__tab--disabled')):hover {
			color: #0770CF;
		}
	}
	.react-tabs__tab:focus {
		border: none;
		box-shadow: none;
		border-bottom: 1px solid #0770CF;
		outline: none;
	}
	.react-tabs__tab:focus::after {
		height: 0!important;
	}
	.react-tabs__tab--selected {
		background: #fff;
		color: #0770CF;
		font-weight: bold;
		border-bottom: 1px solid #0770CF;
	}

	.react-tabs__tab--disabled {
		color: GrayText;
		cursor: default;
	}

	.react-tabs__tab:focus:after {
		content: "";
		position: absolute;
		height: 5px;
		left: -4px;
		right: -4px;
		bottom: -5px;
		background: #fff;
	}

	.react-tabs__tab-panel {
		display: none;
	}

	.react-tabs__tab-panel--selected {
		display: block;
	}
		/**
	* ----------------------------------------------
	* Demo styles
	* ----------------------------------------------
	**/
	.accordion {
			border: 0px solid rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			width: 100%;
	}

	.accordion__item + .accordion__item {
			border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.accordion__button {
			background-color: #fff;
			cursor: pointer;
			padding: 8px;
			text-align: left;
			border: none;
			color: #004D80;
			font-size: 16px;
			letter-spacing: 0.23px;
			line-height: 30px;
			font-weight: 600;
			outline: none;
	}

	.accordion__button h3 {
		color: #004D80 !important;
		font-size: 24px !important;
		letter-spacing: 0.34px;
		line-height: 29px;
		display: -webkit-inline-box;
	}

	.accordion__button:hover {
			background-color: #fff;
	}

	.accordion__button:before {
			display: inline-block;
			content: '';
			height: 8px;
			width: 8px;
			margin-right: 12px;
			border-bottom: 2px solid currentColor;
			border-right: 2px solid currentColor;
			transform: rotate(-45deg);
	}

	.accordion__button[aria-expanded='true']::before,
	.accordion__button[aria-selected='true']::before {
			transform: rotate(45deg);
	}

	.accordion__panel {
		padding: 20px;
		animation: fadein 0.35s ease-in;
		text-align: left;
	}

	.accordion__panel span {
		color: #171515;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03px;
		margin: 0 auto;
		text-align: left;
	}

	/* -------------------------------------------------- */
	/* ---------------- Animation part ------------------ */
	/* -------------------------------------------------- */

	@keyframes fadein {
			0% {
					opacity: 0;
			}

			100% {
					opacity: 1;
			}
	}

	:target::before {
		content: '';
		display: block;
		height: 123px;
		margin: -123px 0 0;
	}
	/* TABS */
	.react-tabs {
		-webkit-tap-highlight-color: transparent;
		width: 900px;

		&__tab-list {
			border-bottom: 1px solid #0095FF;
			margin: 0 0 10px;
			padding: 0;
		}

		&__tab {
			display: inline-block;
			border: 1px solid #0095FF;
			border-bottom: none;
			bottom: -1px;
			position: relative;
			list-style: none;
			padding: 14px 12px;
			cursor: pointer;
			color: #0095FF;
			font-size: 16px;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			letter-spacing: 0.23px;
			line-height: 19px;

			&--selected {
				background: #0095FF;
				border: 1px solid #0095FF;
				color: #FFFFFF;
				text-transform: uppercase;
				padding: 14px 0;
				border-radius: 0;
			}

			&--disabled {
				color: GrayText;
				cursor: default;
			}

			&:focus {
				box-shadow: 0 0 5px hsl(208, 99%, 50%);
				border-color: hsl(208, 99%, 50%);
				outline: none;

				&:after {
					content: "";
					position: absolute;
					height: 5px;
					left: -4px;
					right: -4px;
					bottom: -5px;
					background: #fff;
				}
			}
		}

		&__tab-panel {
			display: none;

			&--selected {
				display: block;
			}
		}
	}
	.carousel .slide {
		background: #fff;
	}
	.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    box-shadow: 1px 1px 2px rgba(0,0,0,.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
	}
	.carousel .slide .legend {
		font-family: 'Barlow Semi Condensed',sans-serif;
		text-transform: uppercase;
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
		left: 41%;
    margin-left: 0;
    width: 250px;
    border-radius: 30px;
    background-color: #026FC2;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 14px;
    font-size: 16px;
		line-height: 17px;
		font-weight: 600;
    text-align: center;
    opacity: 1;
    transition: opacity .35s ease-in-out;
		:hover {
			opacity: 0.9;
			transition: color 0.25s ease-out;
		}
	}
	@media (min-width: 260px) and (max-width: 736px) {
    .carousel .slide .legend {
			left: 20%;
			bottom: 30px;
		}
  }
	.slick-dots li button:before {
		font-size: 10px;
		top: 10px;
		opacity: 0.8;
    color: rgba(255, 255, 255, 0.5);;
	}
	.slick-dots li.slick-active button:before {
		color: #fff;
		font-size: 16px;
	}
	.slick-prev, .slick-next {
		width: 32px;
    height: 32px;
	}
	.slick-prev:before, .slick-next:before {
		color: #026FC2;
		font-size: 32px;
	}
	.slick-next {
		right: -48px;
	}
	.slick-prev {
    left: -60px;
	}
	.styles_modal__gNwvD {
		padding: 0;
	}
	.styles_closeButton__20ID4 {
		background: white;
	}
	/* STICK */
		#gfg-side-sticky-tab {
			position: fixed;
			top: 60vh;
			left: 50px;
			z-index: 9999;
		}
		#gfg-side-sticky-tab div {
			margin: 0px;
			padding: 0px;
		}
		#gfg-side-sticky-tab .sticky-container {
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			border-radius: 0px 0px 6px 6px;
			left: -190px;
			position: fixed;
			transition-property: left;
			transition-duration: .6s;
			width: 195px;
		}
		#gfg-side-sticky-tab .sticky-container:hover {
			left: -10px;
		}
		#gfg-side-sticky-tab .gfg-callout {
			position: relative;
			display: block;
			border-radius: 0px 0px 6px 6px;
		}
		#gfg-side-sticky-tab .slideout {
			position: relative;
			vertical-align: middle;
			height: 182px;
			display: block;
			background-color: #fff;
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			overflow-y: scroll;
			direction: rtl;
			outline: 1px solid #ccc;
		}
		#gfg-side-sticky-tab .slideout .inner {
			text-align: center;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item {
			padding: 0px;
			font-size: 14px;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item a {
			color: #404040;
			text-decoration: none;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item .hand-write-icon {
			font-size: 4rem;
		}
		#gfg-side-sticky-tab .slideout .inner .inner-item a:hover {
			color: #000;
		}
		#gfg-side-sticky-tab .gfg-callout .slide-out-btn {
			color: white;
			background-color: #3fa9f5;
			height: 40px;
			width: 41px;
			margin: 0;
			padding: 0;
			display: block;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			border-radius: 0px 0px 6px 6px;
			position: absolute;
			right: 5px;
			border: 0;
			cursor: pointer;
		}
		.react-responsive-modal-modal {
			max-width: 100% !important;
			background: transparent !important;
			box-shadow: none;
		}
		.react-responsive-modal-closeButton {
			top: -12px;
    	right: 19px;
			background: #fff;
			outline: none;
			
		}
		.react-responsive-modal-overlay {
			padding-top: 3rem;
		}
		.slick-dots {
			bottom: 24px;
		}
`