import React, { Fragment, useState } from 'react'
import Logo from '../images/logo.svg'
import { Link } from 'gatsby'
import * as Scroll from 'react-scroll'
import ResponsiveMenu from './navbar/ResponsiveNavbar'
import OpenIcon from '../images/open_menu.svg'
import CloseIcon from '../images/close_menu.svg'
import Instagram from '../images/instagram.svg'
import {
  navBar,
  container,
  logo,
  navBarMenu,
  // SingUpBtn,
  // LoginBtn,
  // GoToDashboard,
  navMenu,
  mobileMenu,
  togle,
  linkTo,
  socialIcons,
  editionTitle,
  menuPocket
} from './styles/Navbar.styles'

const NavBar = props => {
  const [visible, setVisible] = useState(false)
  return (
    <div css={navBar}>
      <div css={container}>
        <div css={logo} title='Olimpíada Pocket'>
          <Link
            to='/header'
            offsetTop={100}
            onFocus={() => setVisible(false)}
          >
            <Link to={props.isLogged ? '/home' : '/'}><img src={Logo} alt='Logo' /></Link>
          </Link>
        </div>
        <div css={navMenu}
          style={{
            display: props.isMenuDisabled ? 'none' : 'flex'
          }}>
          <ResponsiveMenu
            menuOpenButton={<img src={OpenIcon} alt='/' />}
            menuCloseButton={<img src={CloseIcon} alt='/' />}
            changeMenuOn='960px'
            largeMenuClassName={navBarMenu}
            smallMenuClassName={mobileMenu}
            menuVisible={visible}
            setMenu={setVisible}
            menu={
              <div css={togle}>
                {props.location && !props.location.pathname.match(/^\/(#.*|\?.*)*$/)?
                  (
                    <Fragment>
                      <Link
                        to='/#a-olimpiada'
                        css={linkTo}
                      >
                        O QUE É A OLIMPÍADA POCKET?
                      </Link>
                      <Link
                        to='/pocketv'
                        css={linkTo}
                      >
                        POCKETV
                      </Link>
                      <div css={menuPocket} style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                        <div css={editionTitle}>Edições</div>
                        <hr style={{ marginBottom: 6 }} />
                        <Link
                          to='/olimpiada-pocket-coronavirus'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET CORONAVÍRUS
                        </Link>
                        <Link
                          to='/olimpiada-pocket-segunda-guerra'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET SEGUNDA GUERRA MUNDIAL
                        </Link>
                        <Link
                          to='/olimpiada-pocket-1984'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET 1984 - GEORGE ORWELL
                        </Link>
                        <Link
                          to='/olimpiada-pocket-agrobusiness'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET AGRO
                        </Link>
                      </div>
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <Scroll.Link
                        to='a-olimpiada'
                        smooth={true} 
                        duration={500}
                      >
                        O QUE É A OLIMPÍADA POCKET?
                      </Scroll.Link>
                      <Link
                        to='/pocketv'
                        css={linkTo}
                      >
                        POCKETV
                      </Link>
                      <div css={menuPocket} style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                        <div css={editionTitle}>Edições</div>
                        <hr style={{ marginBottom: 6 }} />
                        <Link
                          to='/olimpiada-pocket-coronavirus'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET CORONAVÍRUS
                        </Link>
                        <Link
                          to='/olimpiada-pocket-segunda-guerra'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET SEGUNDA GUERRA MUNDIAL
                        </Link>
                        <Link
                          to='/olimpiada-pocket-1984'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET 1984 - GEORGE ORWELL
                        </Link>
                        <Link
                          to='/olimpiada-pocket-agrobusiness'
                          smooth={true} 
                          duration={500}
                        >
                          POCKET AGRO
                        </Link>
                      </div>
                    </Fragment>
                  )
                }
                {/* <Fragment>
                  {
                    !props.isLogged
                      ? (
                        <Fragment>
                          <a
                            href='http://bit.ly/sapientia20'
                            css={SingUpBtn}
                          >
                            Inscreva-se
                          </a>
                          <a
                            css={LoginBtn}
                            href='https://app.olimpiadadofuturo.com.br/'
                          >
                            Login
                          </a>
                        </Fragment>
                      )
                      : <Link
                        to='/home'
                        css={GoToDashboard}>
                          Login
                      </Link>
                  }
                </Fragment> */}
                <div css={socialIcons}>
                  <a href='https://www.instagram.com/olimpiadapocket/' target='_blank' rel='noopener noreferrer'><img src={Instagram} alt='Instagram' /></a>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
